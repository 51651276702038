import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Chip, Drawer, Link, Grid, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Logo from './Logo';

const MainSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('md'));

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={!lgUp && openMobile}
      variant="temporary"
      PaperProps={{
        sx: {
          background: "url(/static/backgrounds/background-03.jpg)",
          width: 256,
          paddingY:3
        }
      }}
    >
      <Grid
            container
            md={{
              display: "none",
            }}
          >
            <Grid item xs={12} md={3}>
              <Button
                  href="/">
                <Typography
                  sx={{
                    color: "#FFFFFF !important",
                    textAlign: "center",
                    fontFamily: "Oswald",fontSize: "1.5rem",
                    textShadow: "0px 0px 1px black, 0px 3px 7px black, 0px 1px black , 0px 3px 2px black",
                  }}
                >
                  Inicio
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                  href="/nuestros-trabajos">
                <Typography
                  sx={{
                    color: "#FFFFFF !important",
                    fontFamily: "Oswald",fontSize: "1.5rem",
                    textShadow: "0px 0px 1px black, 0px 3px 7px black, 0px 1px black , 0px 3px 2px black",

                  }}
                >
                  Nuestros trabajos
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                  href="/nosotros">
                <Typography
                  sx={{
                    color: "#FFFFFF !important",
                    fontFamily: "Oswald",
                    fontSize: "1.5rem",
                    textShadow: "0px 0px 1px black, 0px 3px 7px black, 0px 1px black , 0px 3px 2px black",
                  }}
                >
                  Nosotros
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                  href="/contacto">
                <Typography
                  sx={{
                    color: "#FFFFFF !important",
                    fontFamily: "Oswald",fontSize: "1.5rem",
                    textShadow: "0px 0px 1px black, 0px 3px 7px black, 0px 1px black , 0px 3px 2px black",
                  }}
                >
                  Contacto
                </Typography>
              </Button>
            </Grid>
          </Grid>
    </Drawer>
  );
};

MainSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default MainSidebar;
