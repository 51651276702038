import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Typography, Card, CardHeader, Button, Chip, Divider, IconButton, Link, Toolbar, Avatar, Image, Grid } from '@material-ui/core';
import MenuIcon from '../icons/Menu';
import Logo from './Logo';
import MailTwoToneIcon from '@mui/icons-material/MailTwoTone';
import FactoryRoundedIcon from '@mui/icons-material/FactoryRounded';
import WhatsappTwoToneIcon from '@mui/icons-material/WhatsappTwoTone';

const MainNavbar = (props) => {
  const { onSidebarMobileOpen } = props;
  const { user } = useAuth();

  return (
    <AppBar
      sx={{
        background: "url(/static/pattern.jpg)" ,
        color: 'text.secondary',
        boxShadow: 'none',
        position: 'absolute',
        overflow: 'hidden',
      }}
      
    >
      <Box
      sx={{
        marginY: 1,
      }}>
      <Grid container spacing={1}>

        {/* LOGO */}
      <Grid item xs={12} md={9}>
      <Toolbar sx={{ minHeight: 64 }}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              md: 'none'
            }
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <RouterLink to="/">
        <img
            alt="logo"
            src="/static/logo.png"
            height="70"
            />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            alignItems: 'center',
            display: {
              md: 'flex',
              xs: 'none'
            }
          }}
        >
        </Box>
      </Toolbar>
      </Grid>

      {/* LOGO WHATSAPP */}
      <Grid item xs={12} md={3}>
        <Box
        sx={{
          display: 'flex',
        }}>
          <WhatsappTwoToneIcon sx={{
                color: "#18A300",
              }} fontSize="large" />
          <Typography
          variant="body1"
          sx={{
            fontFamily: 'Oswald',
            color:"black",
            marginTop:.5
          }}>(+54) 11-5810-3519</Typography>
        </Box>
        <Box
        sx={{
          display: 'flex',
        }}>
          <MailTwoToneIcon 
          sx={{
            color: "#294c82",
          }} fontSize="large" />
          <Typography
          variant="body1"
          sx={{
            color:"black",
            fontFamily: 'Oswald',
            marginTop:.5
          }}>contacto@gonmec.com</Typography>
        </Box>
      </Grid>


      </Grid>
      </Box>


    </AppBar>
  );
};

MainNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default MainNavbar;
